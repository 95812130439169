<script>
import DropZone from '@components/DropZone'
import ImageExtensions from '@constants/ImageExtensions'
import InputMixin from '@mixins/InputMixin'
import { castArray, isEqual, last, uniq } from 'lodash'
import { filesToArray, hasFileType, toExtension } from '@helpers/FileHelper'

export default {
  components: {
    DropZone,
  },
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: The accept attribute for the file input
    accept: { type: String, default: '' },

    // Public: Whether or not to display the file name and icon next to the file input button.
    buttonOnly: { type: Boolean, default: false },

    // Public: Text to display in the button that triggers the file picker modal
    buttonText: { type: String, default () { return this.multi ? 'global.inputs.choose_files' : 'global.inputs.choose_file' } },

    // The icon name for the upload button (that triggers the file picker modal)
    buttonIcon: { type: String, default: 'upload' },

    // Public: Whether or not to always display drop area and the large input
    large: { type: Boolean, default: false },

    // Public: True if the input should allow multiple files to be uploaded. False for single file mode
    multi: { type: Boolean, default: false },

    // Public: Object/Array representing the file(s)
    value: { type: [Object, Array], default () { return this.multi ? [] : {} } },
  },
  computed: {
    files () {
      return castArray(this.value || []).filter(file => file.name)
    },
    fileExtensions () {
      return uniq(this.files.map(file => toExtension(file.name)))
    },
    icon () {
      if (this.fileExtensions.length > 0 && this.fileExtensions.every(extension => ImageExtensions.has(extension))) {
        return 'image'
      }
      return 'document'
    },
    fileCountPrefix () {
      return this.files.length === 0 ? 'No' : this.files.length
    },
    fileValidationErrors () {
      return this.files.every(file => this.validateFile(file.file || file)) ? null : 'Invalid file'
    },
  },
  watch: {
    // Internal: Ensure we reset the input state whenever the value changes.
    value (value) {
      if (this.$refs.file) this.$refs.file.value = ''
    },
    fileValidationErrors (errors) {
      this.$emit('input:customErrors', errors)
    },
  },
  methods: {
    fileId (file) {
      const fileObject = file.file || file
      return file.url || (file.name + (fileObject && (String(fileObject.lastModified) + String(fileObject.size))))
    },
    isNewFile (fileToCheck) {
      return !this.files.some(oldFile => this.fileId(oldFile) === this.fileId(fileToCheck))
    },
    // Internal: Notifies of a change in the selected files.
    notifyFileChange (files) {
      if (isEqual(this.files, files)) return
      this.$emit('input', this.multi ? files : last(files) || null)
    },
    // Internal: Checks if the file type matches accept prop
    validateFile (file) {
      if (!this.accept) return true // No validation if `accept` is not specified
      const allowedTypes = this.accept.split(',').map(type => type.trim())
      return allowedTypes.some(type => file.type === type)
    },
    // Internal: Handles a file that was drag and dropped, ignoring directories.
    onFileDrop (event) {
      const fileList = event.dataTransfer.files
      const filesWithoutDirectories = filesToArray(fileList).filter(hasFileType)
      this.onFilesSelected(filesWithoutDirectories)
    },
    // Internal: Adds the selected files to the currently selected list.
    onFilesSelected (fileList) {
      const files = filesToArray(fileList).map(file => ({ name: file.name, file }))
      if (files.length === 0) return
      this.notifyFileChange(this.multi ? this.files.concat(files.filter(this.isNewFile)) : files)
    },
    // Internal: Removes the specified file from the selected list.
    removeFile (fileToRemove) {
      this.notifyFileChange(this.files.filter(file => file !== fileToRemove))
    },
  },
}
</script>

<template>
  <div :class="{ 'with-hint': hint, error }" class="file-input">
    <Label v-if="label || $slots.label" v-bind="{ required, hint }" :class="{ error }">
      {{ label }}
      <template slot="labelExtras">
        <slot name="labelExtras"/>
      </template>
    </Label>
    <DropZone class="file-input__control" :class="{ error, 'file-input__control--large': large }" @drop="onFileDrop">
      <div v-if="large" class="file-input__drop-hint">
        <h3>Drag files into the box</h3>
        <Icon name="upload" size="large"/>
        <h3>or click here</h3>
      </div>
      <div class="file-input__upload-container">
        <Button class="file-input__upload-button" :label="buttonText" :icon="buttonIcon" @click="$refs.file.click()"/>
        <span v-if="!buttonOnly" class="file-input__helper-text">{{ 'file' | pluralize(fileCountPrefix) }} chosen</span>
      </div>
      <div v-if="!buttonOnly && files.length" class="file-input__selected-files">
        <transition-group name="list-item">
          <Tag v-for="(file, index) in files" :key="fileId(file)" removeBtn class="file-input__selected-file" @removed="removeFile(file)">
            <slot name="file" v-bind="{ file }">
              <Button v-if="!buttonOnly" disabled class="file-input__file-icon" :icon="icon"/>
            </slot>
            <NavigationLink
              v-if="file.url && !file.url.includes('base64')"
              v-tooltip.ellipsisOnly="file.name"
              :to="file && file.url"
              target="_blank"
              class="file-input__file-name"
            >
              {{ file.name }}
            </NavigationLink>
            <span v-else :key="`file-name-${file}`" v-tooltip.ellipsisOnly="file.name" class="file-input__file-name">{{ file.name }}</span>
            <slot name="fileExtras" v-bind="{ file, index }"/>
          </Tag>
        </transition-group>
      </div>
      <template #dropArea>
        <Icon v-if="files.length > 0" name="download" size="large"/>
        Drop files here
      </template>
    </DropZone>
    <input
      ref="file"
      :accept="accept"
      class="file-input__file-input"
      type="file"
      :multiple="multi"
      @change="onFilesSelected($event.target.files)"
    >
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
.file-input {
  position: relative;
}

.file-input__control {
  border: solid 1px $input-border-color;
  border-radius: $radius-normal;
  padding: 8px;
  position: relative;

  &.error {
    border-color: $input-error-color;
  }
}

.file-input__upload-container {
  align-items: center;
  display: flex;
}

.file-input__file-input {
  opacity: 0;
  position: absolute;
  right: 0;
  visibility: hidden;
}

.file-input__upload-button {
  color: inherit;
  white-space: nowrap;
}

.file-input__helper-text {
  color: $fc-html-light;
  display: inline-block;
  font-size: $fs-secondary;
  margin-left: auto;
}

.file-input__selected-files {
  border-top: $br-light;
  margin: 8px -8px -8px;
  padding: 0 8px 8px;

  .error & {
    border-color: $input-error-color;
  }
}

.file-input__file-name {
  @include ellipsize(inline-block);

  align-self: center;
  font-size: $fs-secondary;
  line-height: $lh-normal;
  margin-left: 8px;
  margin-right: 4px;
  min-width: 0;
}

a.file-input__file-name {
  color: $anchor-color;
  text-decoration: underline;
}

.file-input__selected-file {
  display: flex;
  font-size: $fs-secondary;
  margin-top: 8px;
  width: 100%;

  & ::v-deep .tag-label {
    display: flex;
    min-width: 0;
  }
}

.file-input__control--large {
  border: 1px dashed $br-color-light;
  padding: 24px;

  .file-input__upload-container {
    flex-direction: column;
  }

  .file-input__helper-text {
    margin-left: 0;
  }
}

.file-input__drop-hint {
  h3 {
    text-align: center;
  }

  .icon {
    margin: 0 auto;
  }
}

@include list-item-transition(0.2s);
</style>
